<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div
          v-if="!targetUser"
          class="profile-progress d-flex justify-center align-center"
        >
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else class="user-params-card">
          <UserInfo
            :pUser="targetUser"
            :pMode="mode"
            :pLoading="loading"
            @update="onUpdateUser"
          >
            <template v-slot:actions>
              <v-spacer />
              <v-btn v-if="isViewMode" class="edit-btn" text color="primary" @click="editUser">Edit</v-btn>
              <v-btn v-if="isEditMode" class="close-btn" text @click="viewUser">Cancel</v-btn>
              <v-btn v-if="isEditMode" class="save-btn" text color="primary" :disabled="!isDirty || !isValid" @click="saveUser">Save</v-btn>
            </template>
          </UserInfo>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";
import UserInfo from "@/components/user/UserInfo";
import { UserInfoMode } from "@/components/user/user-info-types";
import { UserService } from "@/services/user.service";

export default {
  name: "UserProfile",

  components: { UserInfo },

  data: () => ({
    targetUser: undefined,
    updatedUser: undefined,
    loading: false,
    mode: UserInfoMode.VIEW,
    isDirty: false,
    isValid: false,
  }),

  computed: {
    ...mapState("userModule", ["user"]),

    cardTitle() {
      return this.targetUser && this.targetUser.id !== this.user?.id
        ? "User Profile"
        : "My Profile";
    },

    isViewMode() {
      return this.mode === UserInfoMode.VIEW;
    },

    isEditMode() {
      return this.mode === UserInfoMode.EDIT;
    },
  },

  methods: {
    editUser() {
      this.mode = UserInfoMode.EDIT;
    },

    viewUser() {
      this.mode = UserInfoMode.VIEW;
      this.isDirty = false;
      this.updatedUser = undefined;

      const tmp = this.targetUser;
      this.targetUser = undefined;
      Vue.nextTick(() => {
        this.targetUser = tmp;
      });
    },

    onUpdateUser(data) {
      this.isDirty = data.isDirty;
      this.isValid = data.isValid;
      this.updatedUser = data.user;
    },

    async saveUser() {
      this.loading = true;

      try {
        await UserService.updateUser(this.updatedUser, this.targetUser);
        await this.reloadTargetUser();
        this.viewUser();
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    },

    async reloadTargetUser() {
      this.targetUser = undefined;

      Vue.nextTick(async () => {
        const userId = this.$route.params.id;

        if (userId && userId !== this.user.id) {
          try {
            this.targetUser = await this.$store.dispatch("usersModule/getUserById", userId);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        } else {
          this.targetUser = this.user;
        }
      });
    },
  },

  mounted() {
    this.reloadTargetUser();
  },
};
</script>

<style lang="scss">
.user-params-card {
  width: 40%;
}

.profile-progress {
  height: 90vh;
}

@media (max-width: 1000px) {
  .user-params-card {
    width: 100%;
  }
}
</style>
